// Determine the env from the window host

const ENVIRONMENTS = ['development', 'staging', 'production'] as const;
type AvailableEnvironmentsTuple = typeof ENVIRONMENTS;
type AvailableEnvironment = AvailableEnvironmentsTuple[number];

export default function determineEnvironmentFromHost(
    hostUrl: string,
): AvailableEnvironment {
    // This is the format for the frontend runtime deployed in eks api-apps
    // e.g. <minesite id>.frontend.staging.max-mine.com
    const apiAppsUrl = new RegExp(/.*\..*\.(.*)\.max-mine\.com$/).exec(hostUrl);

    if (apiAppsUrl !== null) {
        const environment = (apiAppsUrl[1] as unknown) as AvailableEnvironment;
        if (ENVIRONMENTS.includes(environment)) return environment;
    }

    const productionUrl = hostUrl.match(
        /(.*).([A-Za-z]+)\.([A-Za-z]+)\.max-mine\.com$/,
    );
    if (productionUrl) return 'production';

    return 'development';
}
