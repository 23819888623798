/* eslint-disable import/no-cycle */
import { select, take, takeLatest, fork, all } from 'redux-saga/effects';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import * as appActions from '../App/Actions';
import * as authActions from '../Auth/Actions';
import history from '../App/createHistory';
import constants from '../Constants/googleAnalytics';
import determineEnvironmentFromHost from '@rs/core/utils/determineEnvironmentFromHost';
import { Action } from '../Lib/reduxUtils';
import { getLabelByPath } from './utils';
import { GoogleAnalyticsEventBuilder } from '../Utils/userAnalytics/googleAnalyticsEventBuilder';
import { isActionNameValid } from '../Utils/userAnalytics/events';
import determineMinesiteFromHost from '@rs/core/utils/determineMinesiteFromHost';
import * as thisModule from './analyticsWatchers';
import { actions as PowerBiReportsAction } from '../PowerBi/Modules/powerBiReports';

// NOTE: Think carefully before changing the string value of these
// as it will probably break the reports on Google analytics
// TO BE DEPRECATED
export const ANALYTICS_CATEGORIES = {
    AUTH: 'Auth',
};

/**
 * The entry point for init hot jar
 * @return {IterableIterator<*>}
 */
export function* setupHotjar() {
    yield take(appActions.APP_INIT_SUCCESS);

    yield hotjar.initialize(3319402, 6);
    if (hotjar.initialized()) {
        console.log('hotjar initialised');
    }
}

/**
 * The entry point for tracking analytic actions
 * @return {IterableIterator<*>}
 */
export function* setupGoogleAnalytics() {
    yield take(appActions.APP_INIT_SUCCESS);

    // setup google analytics
    // NOTE: install GA debugging plugin if you want to see what's being sent
    yield initializeGoogleAnalytics();

    // Track events
    while (true) {
        const action = yield take('*');

        yield fork(trackActionWithGoogleAnalytics, action);
        yield fork(trackPageViewWithGoogleAnalytics, action);

        if (action.type === PowerBiReportsAction.FETCH_REPORT_SUCCESS) {
            console.log(action);

            yield fork(trackCustomReportPageViewWithGoogleAnalytics, action);
        }
    }
}

/**
 * Reads from a Redux action and sends an event to google analytics
 * @param action A redux action
 * @return {IterableIterator<*>}
 */
export function trackActionWithGoogleAnalytics(action: Partial<Action>) {
    const [, pageHashRoute] = window.location.hash.split('#');
    // Any redux events with valid meta data opt in for Google analytics
    const actionAnalytics = action.meta && action.meta.analytics;

    if (
        actionAnalytics == null ||
        !isActionNameValid(actionAnalytics.category, actionAnalytics.action)
    ) {
        return;
    }

    ReactGA.event(actionAnalytics);
    ReactGA.send({
        hitType: 'pageview',
        page: pageHashRoute,
        title: actionAnalytics.label,
    });
}

/**
 * Sends pageviews to google analytics
 * @return {IterableIterator<*>}
 */
export function trackPageViewWithGoogleAnalytics(action: Partial<Action>) {
    if (
        action.type == '@@router/LOCATION_CHANGE' &&
        action.payload &&
        action.payload?.action !== 'REPLACE' // Do not send GA event on URL sync
    ) {
        const { location } = action.payload;
        let pathTitle = getLabelByPath(location.pathname) || 'No title';

        // Custom report is the only case where it gets the dynamic title, skip
        if (pathTitle.includes('Custom Report')) {
            // it will be tracked in trackCustomReportPageViewWithGoogleAnalytics
            return;
        }

        // Record feature usage event
        if (pathTitle !== 'No title') {
            trackActionWithGoogleAnalytics({
                meta: {
                    analytics: new GoogleAnalyticsEventBuilder(
                        'FeatureUsage',
                        'Visit',
                    )
                        .setLabel(pathTitle)
                        .build(),
                },
            });
        }
    }
}

/**
 * Sends custom report pageviews to google analytics
 * @return {IterableIterator<*>}
 */
export function trackCustomReportPageViewWithGoogleAnalytics(
    action: Partial<Action>,
) {
    let lastPathname = history.location.pathname;
    const pathTitle = getLabelByPath(lastPathname) || 'No title';

    trackActionWithGoogleAnalytics({
        meta: {
            analytics: new GoogleAnalyticsEventBuilder('FeatureUsage', 'Visit')
                .setLabel(
                    `Custom Report - ${action.payload?.reportName}` ||
                        pathTitle,
                )
                .build(),
        },
    });
}

export function* initializeGoogleAnalytics(
    options: { [id: string]: any } = {},
) {
    const hostUrl = document.domain || '';

    const currentEnvironment = determineEnvironmentFromHost(hostUrl);
    if (!currentEnvironment) return;

    const gaTrackingId = constants[currentEnvironment].GA_MEASUREMENT_ID;
    if (!gaTrackingId) return;

    const minesite_id = determineMinesiteFromHost(hostUrl);
    if (minesite_id) {
        options['minesite_id'] = minesite_id;
    }

    ReactGA.isInitialized = false;
    yield ReactGA.initialize(gaTrackingId, { gaOptions: options });
}

export function* trackUserInformationWithGoogleAnalytics(action: Action) {
    const options: { [id: string]: any } = {};

    if (action.payload.CurrentUser.Uuid !== undefined) {
        options['userId'] = action.payload.CurrentUser.Uuid;
    }

    if (action.payload.CurrentUser.Role !== undefined) {
        options['role'] = action.payload.CurrentUser.Role;
    }

    if (action.payload.CurrentUser.Department !== undefined) {
        options['department'] = action.payload.CurrentUser.Department;
    }

    if (action.payload.CurrentUser.IsAutomatedUser !== undefined) {
        options['isAutomatedUser'] = action.payload.CurrentUser.IsAutomatedUser;
    }

    if (action.payload.CurrentUser.IsMaxmineUser !== undefined) {
        options['isMaxmineUser'] = action.payload.CurrentUser.IsMaxmineUser;
    }

    if (action.payload.CurrentUser.IsSharedUser !== undefined) {
        options['isSharedUser'] = action.payload.CurrentUser.IsSharedUser;
    }

    yield thisModule.initializeGoogleAnalytics(options);
}

export function* untrackUserInformationWithGoogleAnalytics() {
    yield thisModule.initializeGoogleAnalytics();
}

export default function* watch() {
    if (process.env.NODE_ENV !== 'production') return;

    yield all([
        setupGoogleAnalytics(),
        setupHotjar(),
        takeLatest(
            appActions.FETCH_USER_INFORMATION_SUCCESS,
            trackUserInformationWithGoogleAnalytics,
        ),
        takeLatest(
            authActions.LOGOUT,
            untrackUserInformationWithGoogleAnalytics,
        ),
    ]);
}
