import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '@rs/core/types';
import { ComponentToPrint } from '@rs/core/components';
import { Layout } from '@rs/core/falcon';
import { FEATURE_IDS } from '@rs/core/features';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { stateSelectors } from '../Reducers';
import * as actions from '../Actions';
import LeagueTableFilters from './LeagueTableFilters';
import LeagueTableTable from './LeagueTableTable';
import * as appActions from '../../App/Actions';
import { PageControlFileExport } from '../../Components/Page';
import filtersToFilename from '../../Utils/Filters/filtersToFilename';
import {
    getComponentConfig,
    getMinesiteDisplayName,
    getPageTitle,
} from '../../App/Selectors';
import PageControlsContainer from '../../Components/PageControlsContainer';

const { SubHeader, Content } = Layout;

export const SECONDS_FORMAT_STRING = 'hh:mm';

class LeagueTable extends Component {
    constructor(props) {
        super(props);
        this.handleExportCSVClick = this.handleExportCSVClick.bind(this);
    }

    componentDidMount() {
        this.props.updateFiltersWithUrlParams();
    }

    handleExportCSVClick() {
        const {
            exportToCSV,
            filteredCornerLeague,
            filteredCornerLeagueTotals,
            filters,
            minesiteDisplayName,
            tableOverrides,
        } = this.props;

        const exportCSVConfig = [
            { accessor: 'Driver', CSVDisplayName: 'Operator Name' },
            { accessor: 'Crew', CSVDisplayName: 'Crew' },
            {
                accessor: 'TotalCorners',
                CSVDisplayName: 'Total Corners',
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_COUNT,
            },
            {
                accessor: 'TotalRegionEvents',
                CSVDisplayName: 'Speed Zone Passes',
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_ZONES_COLUMN,
            },
            {
                accessor: 'TotalRampEvents',
                CSVDisplayName: 'Total Ramp Passes',
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_RAMPS_COLUMN,
            },
            {
                accessor: 'DrivingDuration',
                CSVDisplayName: `Driving Duration (${SECONDS_FORMAT_STRING})`,
                featureId:
                    FEATURE_IDS.LEAGUE__TABLE_SHOW_DRIVING_DURATION_COLUMN,
            },
            {
                accessor: 'OverspeedConformance',
                CSVDisplayName: 'Corner Conformance Score',
                featureId:
                    FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_CONFORMANCE_COLUMN,
            },
            {
                accessor: 'StraightConformance',
                CSVDisplayName: 'Zone Conformance Score',
            },
            {
                accessor: 'RampConformance',
                CSVDisplayName: 'Ramp Conformance Score',
            },
            {
                accessor: 'SpotConformance',
                CSVDisplayName: 'Spot Conformance Score',
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_SPOT_COLUMN,
            },
            {
                accessor: 'DumpConformance',
                CSVDisplayName: 'Dump Conformance Score',
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_DUMP_COLUMN,
            },
            {
                accessor: 'ProductivityConformance',
                CSVDisplayName: 'Operator Efficiency Conformance Score',
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN,
            },
            {
                accessor: 'TotalConformance',
                CSVDisplayName: 'Overall Conformance Score',
            },
        ].map((column) => {
            const overrides =
                tableOverrides &&
                tableOverrides.columns &&
                tableOverrides.columns[column.accessor];
            if (!overrides) {
                return column;
            }
            return {
                ...column,
                ...overrides,
            };
        });

        exportToCSV({
            data: [
                ...filteredCornerLeague,
                {
                    // Add the total label for the final row in the 'Driver' col
                    Driver: 'Overall',
                    ...filteredCornerLeagueTotals,
                },
            ],
            fileKeyConfig: exportCSVConfig,
            filename: filtersToFilename(
                'HaulLeagueTable',
                filters,
                minesiteDisplayName,
            ),
        });
    }

    render() {
        const {
            spinner,
            filteredCornerLeague,
            filters,
            filterOptions,
            handleFilterChange,
            setCornerLeagueOrder,
            filteredCornerLeagueTotals,
            cornerLeagueOrder,
            cornerLeaguePredicate,
            onTableLinkClick,
            cornerLeagueWeightings,
            title,
        } = this.props;

        const isProductivityLinkDisabled = filters.rangeSelected;

        const exportCSVOptions = [
            {
                label: 'Table Results',
                isDisabled: spinner.isActive || !filteredCornerLeague.length,
            },
        ];

        return (
            <React.Fragment>
                <SubHeader>
                    <SubHeader.Left>
                        <SubHeader.Title>{title}</SubHeader.Title>
                    </SubHeader.Left>
                    <SubHeader.Right>
                        <PageControlsContainer>
                            <PageControlFileExport
                                items={exportCSVOptions}
                                onChange={this.handleExportCSVClick}
                            />
                        </PageControlsContainer>
                    </SubHeader.Right>
                </SubHeader>
                <Content>
                    <LoadingSpinner
                        isActive={spinner.isActive}
                        message={spinner.message}
                    />
                    <ComponentToPrint ref={(el) => (this.componentRef = el)}>
                        <LeagueTableFilters
                            filteredCornerLeague={filteredCornerLeague}
                            filters={filters}
                            filterOptions={filterOptions}
                            onDateRangeClick={handleFilterChange}
                            onDateFilterChange={handleFilterChange}
                            onFilterChange={handleFilterChange}
                        />
                        <LeagueTableTable
                            onColumnHeaderClick={setCornerLeagueOrder}
                            cornerLeagueWeightings={cornerLeagueWeightings}
                            filteredCornerLeagueTotals={
                                filteredCornerLeagueTotals
                            }
                            filteredCornerLeague={filteredCornerLeague}
                            currentOrder={cornerLeagueOrder}
                            predicate={cornerLeaguePredicate}
                            onTableLinkClick={onTableLinkClick}
                            isProductivityLinkDisabled={
                                isProductivityLinkDisabled
                            }
                        />
                    </ComponentToPrint>
                </Content>
            </React.Fragment>
        );
    }
}

LeagueTable.propTypes = {
    spinner: types.typeSpinner,
    filteredCornerLeague: PropTypes.arrayOf(types.typeCornerLeagueRow),
    filteredCornerLeagueTotals: types.typeCornerLeagueTotal,
    filters: PropTypes.shape({
        Date: PropTypes.string,
        EndDate: PropTypes.string,
        Shift: PropTypes.string.isRequired,
        Crew: PropTypes.string.isRequired,
        Worker: PropTypes.string.isRequired,
        rangeSelected: PropTypes.bool,
    }).isRequired,
    filterOptions: PropTypes.shape({
        shifts: PropTypes.array.isRequired,
        drivers: PropTypes.array.isRequired,
        crews: PropTypes.array.isRequired,
    }).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    setCornerLeagueOrder: PropTypes.func.isRequired,
    cornerLeagueOrder: PropTypes.string.isRequired,
    cornerLeaguePredicate: PropTypes.bool.isRequired,
    updateFiltersWithUrlParams: PropTypes.func.isRequired,
    onTableLinkClick: PropTypes.func.isRequired,
    exportToCSV: PropTypes.func.isRequired,
    minesiteDisplayName: PropTypes.string.isRequired,
    cornerLeagueWeightings: types.typeConformanceWeighting,
    tableOverrides: PropTypes.shape({
        columns: PropTypes.shape({
            ProductivityConformance: PropTypes.shape({
                label: PropTypes.string,
                tooltip: PropTypes.string,
            }),
        }),
    }),
    title: PropTypes.string,
};

const mapStateToProps = (state) => ({
    spinner: stateSelectors.getSpinnerState(state),
    filteredCornerLeague: stateSelectors.getCornerLeague(state),
    filters: stateSelectors.getFilters(state),
    filterOptions: stateSelectors.getFilterOptions(state),
    filteredCornerLeagueTotals: stateSelectors.getCornerLeagueTotal(state),
    cornerLeaguePredicate: stateSelectors.getCornerLeaguePredicate(state),
    cornerLeagueOrder: stateSelectors.getCornerLeagueOrder(state),
    minesiteDisplayName: getMinesiteDisplayName(state),
    cornerLeagueWeightings: stateSelectors.getCornerLeagueWeightings(state),
    tableOverrides: getComponentConfig(state, 'LeagueTablePage'),
    title: getPageTitle(state, FEATURE_IDS.TAB_ID__LEAGUE_TABLE),
});

const mapDispatchToProps = {
    handleFilterChange: actions.filterUpdated,
    setCornerLeagueOrder: actions.sortTable,
    updateFiltersWithUrlParams: actions.updateFiltersWithUrlParams,
    onTableLinkClick: actions.linkToPage,
    exportToCSV: appActions.exportToCSV,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(LeagueTable);
